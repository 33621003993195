<script>
    import { onMount } from 'svelte';
    import Fecha from './Fecha.svelte';
    export let esp;

    onMount( () => {
        sLightbox()
    } )

    const sLightbox = () => {

        new SimpleLightbox('.gallery a', {
            overlayOpacity: 0.9,
            captionPosition: 'outside',
        })

        // jQuery('.venobox').venobox({
        //     spinner    : 'cube-grid',
        //     spinColor    : '#fbb802',
        //     border: '5px',
        //     bgcolor: '#fbb802',
        //     arrowsColor: '#fbb802',
        //     closeColor : "#fbb802",
        //     infinigall: true,
        //     numeratio: true,
        //     numerationColor: '#fbb802',
        //     titleattr: 'title',
        //     titleColor: '#fbb802',
        // });

        // new VenoBox({
        //     selector: '.venobox',
        //     spinner    : 'grid',
        //     spinColor    : '#fbb802',
        //     border: '5px',
        //     bgcolor: '#fbb802',
        //     infinigall: true,
        //     numeration: true,
        //     titleattr: 'title',
        //     navTouch: false,
        //     toolsColor: '#fbb802',
        // });
    }
</script>
<section id="fechas" class="container-fluid">
    <div class="container my-5 pb-3 sombra card">

        <h2 Class="h2 text-center titulo-seccion">{ esp ? "Fechas Importantes" : "Important Dates" }</h2>
        <h4 class="text-center sub-titulo-seccion">{ esp ? "Información Importante" : "Important Information" }</h4>

        <div id="info" class="gallery row row-cols-2 row-cols-sm-3 row-cols-md-4 d-flex justify-content-center">
            <Fecha url="/assets/img/eventos/fechas.webp" title="Fechas importantes 2024-2025"></Fecha>
            <Fecha url="/assets/img/eventos/nov_01.webp" title="Asueto - Día de Difuntos"></Fecha>
            <Fecha url="/assets/img/eventos/nov_02.webp" title="Concurso Puertas Navideñas"></Fecha>
            <Fecha url="/assets/img/eventos/nov_03.webp" title="Concurso de Ortografía - Primera Ronda"></Fecha>
            <Fecha url="/assets/img/eventos/nov_04.webp" title="Charla Motivacional"></Fecha>
            <Fecha url="/assets/img/eventos/nov_05.webp" title="Semana de Proyectos"></Fecha>
            <Fecha url="/assets/img/eventos/nov_06.webp" title="Concurso de Ortografía - Segunda Ronda"></Fecha>
            <Fecha url="/assets/img/eventos/nov_07.webp" title="Concurso de Ortografía - Final"></Fecha>
            <Fecha url="/assets/img/eventos/nov_08.webp" title="Celebración de Acción de Gracias"></Fecha>
            <Fecha url="/assets/img/eventos/nov_09.webp" title="Asueto Acción de Gracias"></Fecha>
        </div>

    </div>
</section>